import React, { useEffect, useState, useCallback } from 'react'
import Accordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { useTranslation } from 'react-i18next'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { styled } from '@mui/material/styles'
import { useFetch } from '../../../hooks'
import { StaticLoaderLinear } from '../../../components'
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { backend_url } from '../../../settings'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { dateTimeFormatter } from "../../../utils/utils"
import { useUserPrefs } from "../../UserPreferences/ProvideUserPrefs"
import debounce from 'lodash.debounce';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AccordionSummary = styled(
  (props) => (
    <MuiAccordionSummary
      expandIcon={< ArrowRightIcon className='bpx-arrow-icon' />}
      {...props}
    />
  )
)(
  ({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  })
)

export default function TradeExpeditureFilters({ onFilter, filtersData }) {
  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()
  const [loading, setLoading] = useState(false)
  const { prefsData} = useUserPrefs();
  const [expanded, setExpanded] = useState('panel1')

  const [filters, setFilters] = useState([
    {
      field: "YEAR",
      title: t("TradeExpeditiureMonitor.YEAR"),
      formatField: "year",
    },
    {
      field: "REPORT",
      title: t("TradeExpeditiureMonitor.REPORT_VERSION"),
      url: backend_url.trade_expenditure_monitor_filter_values,
      formatField: "singleSelect",
      valueOptions: [],
      firstKeyOption: 'REPORT',
      disabled: true,
      isFetching: false,
      multiple: false,
    },
    {
      field: "PLAN_ACCOUNT",
      url: backend_url.trade_expenditure_monitor_filter_values,
      title: t("TradeExpeditiureMonitor.PLAN_ACCOUNT"),
      formatField: "select",
      valueOptions: [],
      disabled: true,
      firstKeyOption: "PLAN_ACCOUNT_BP_NR",
      secondKeyOption: "PLAN_ACCOUNT_NAME",
      atLeastThreeLetter: true,
      isFetching: true,
      isObject: true,
      multiple: true
    },
    {
      field: "AO_STATUS",
      url: backend_url.trade_expenditure_monitor_filter_values,
      title: t("TradeExpeditiureMonitor.STATUS"),
      valueOptions: [],
      formatField: "select",
      disabled: true,
      firstKeyOption: "AO_STATUS",
      secondKeyOption: "",
      isFetching: false,
      multiple: true,
      isObject: true,
      prevFetch: true,
    },
    {
      field: "PLAN_ACCOUNT_BP_CHANNEL",
      url: backend_url.trade_expenditure_monitor_filter_values,
      title: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_BP_CHANNEL"),
      valueOptions: [],
      formatField: "select",
      disabled: true,
      firstKeyOption: "PLAN_ACCOUNT_BP_CHANNEL",
      secondKeyOption: "",
      isFetching: false,
      multiple: true,
      isObject: true,
      prevFetch: true,
    },
    {
      field: "PLAN_ACCOUNT_BP_SUB_CHANNEL",
      url: backend_url.trade_expenditure_monitor_filter_values,
      title: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_BP_SUB_CHANNEL"),
      valueOptions: [],
      formatField: "select",
      disabled: true,
      firstKeyOption: "PLAN_ACCOUNT_BP_SUB_CHANNEL",
      secondKeyOption: "",
      isFetching: false,
      multiple: true,
      isObject: true,
      prevFetch: true,
    },
  ])
  
  const handleChangeExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const onChangeYear = (dateYear) => {

    const year = String(new Date(dateYear.YEAR).getFullYear())
    setLoading(true)
    request.fetchData(
      `${backend_url.trade_expenditure_monitor_filter_values}/${year}?BUSINESS_UNIT=${value_bu}&filter=REPORT`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    ).then(data => {
      if (data.length) {
        setFilters((prevFilters) => {
          prevFilters.slice(1).forEach(it => {
            it.disabled = false;
            if (it.field === 'REPORT') {
              it.valueOptions = data
            }
          });
          return prevFilters;
        })
        localStorage.setItem('trade-reports', JSON.stringify([Math.max(...data.map(it => it.VERSION))]))

        onFilter({ ...filtersData, ...dateYear,  "YEAR": year, "REPORT": [String(data.sort((a, b) => b.VERSION - a.VERSION)[0].VERSION)] })
      } else {
        setFilters((prevFilters) => {
          prevFilters.slice(1).forEach(it => {
            if (it.field === 'REQUEST') {
              it.disabled = false;
            } else {
              it.disabled = true;
            }
            it.valueOptions = [];
          });
          return prevFilters;
        })

        localStorage.setItem('trade-reports', JSON.stringify([-1]))
        onFilter({ ...filtersData, ...dateYear, "YEAR": year, "REPORT": [] })
      }

      setLoading(false)

    })
    filters.filter(it=>it.prevFetch).map(it=>{
      request.fetchData(
        `${backend_url.trade_expenditure_monitor_filter_values}/${year}?BUSINESS_UNIT=${value_bu}&filter=${it.field}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false
      ).then(data => {
        const filteringData = data.map(item=>{
          if(!item[it.field]){
            item[it.field] = '[Empty]'
          }
          return item
        })
       
        if (filteringData.length) {
          setFilters((prevFilters) => {
            prevFilters.forEach(item => {
              if (item.field === it.field) {
                item.valueOptions = filteringData
              }
            });
            return prevFilters;
          })
        }
      })
    })
  }

  useEffect(() => {
    let cachedfilters = localStorage.getItem("filters")
    const filterObj = cachedfilters ? JSON.parse(cachedfilters) : {}
    if (filterObj["TradeExpeditiureMonitor"]) {
      onChangeYear(filterObj["TradeExpeditiureMonitor"])
    } else {
      onChangeYear({YEAR: new Date()})
    }
  }, [])

  const fetchDataForFilters = (url, filter, filterValue, filters) => {
    request.fetchData(
      `${url}/${filters.YEAR}?BUSINESS_UNIT=${value_bu}&filter=${filter}&filter_pattern=${filterValue}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    ).then(data => {
      setFilters(prev => {
        return prev.map(it => {
          if (it.field === filter) {
            it.valueOptions = data
          }
          return it
        })
      })
    })
  }

  const changeHandler = (url, field, newValue, filters)=>{
    fetchDataForFilters(url, field, newValue, filters)
  }

  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 500)
  , []);

  const displayFilters = () => {
    return filters.map(it => {
      const { formatField, title, field, valueOptions, disabled, isFetching, atLeastThreeLetter, url, firstKeyOption, isObject, secondKeyOption, multiple, onClick } = it;
      switch (formatField) {
        case 'singleSelect':
          return (
            <div className="subtotal-filter-section" key={field}>
              <div className="subtotal-filter-label">{title}</div>
              <div className="subtotal-filter-field">
                <FormControl size="small">
                  <Select
                    className='trade-filter'
                    labelId="demo-simple-select-label"
                    value={filtersData[field] || ''}
                    onChange={(e) => onFilter({ ...filtersData, [field]: [e.target.value] })}
                    size="small"
                  >
                    {valueOptions ?
                      valueOptions.map((item, i) => {
                        return <MenuItem sx={{fontSize: '12px', height: '27px'}} key={i} value={String(item.VERSION)}>{t("TradeExpeditiureMonitor.REPORT_VERSION_MENU_ITEM")}{item.VERSION}; {dateTimeFormatter(item.REPORT_DATE, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}; {item.REQUESTED_BY}</MenuItem>
                      })
                      : null}
                  </Select>
                </FormControl>
              </div>
            </div>
          )
        case 'select':
          return (
            <div className="subtotal-filter-section" key={field}>
              <div className="subtotal-filter-label">{title}</div>
              <div className="subtotal-filter-field">
                <Autocomplete
                  className="subtotal-filter-autocomplete"
                  multiple={true}
                  freeSolo={true}
                  options={valueOptions || []}
                  disabled={disabled}
                  value={filtersData[field] || []}
                  getOptionLabel={(option) => isObject ? Object.values(option).join(' - ') : option}
                  isOptionEqualToValue={(option, value) => {
                    if (isObject) {
                      return option[firstKeyOption] === value[firstKeyOption]
                    }
                    return option === value
                  }}
                  onInputChange={(event, newValue, condition) => {

                    if (atLeastThreeLetter && newValue.length < 3) {
                      return
                    }
                    if (isFetching) {
                      debouncedChangeHandler(url, field, newValue, filtersData)
                    }
                  }}
                  onChange={(e, value, state) => {
                    if(e.type==="keydown"&&e.key==='Enter'){
                      return
                    }
                    if (value.length) {
                      if (multiple) {
                        onFilter({ ...filtersData, [field]: value })
                      } else {
                        onFilter({ ...filtersData, [field]: [value.at(-1)] })
                      }
                    } else {
                      onFilter({ ...filtersData, [field]: [] })
                    }
                  }
                  }
                  renderOption={(props, option) => {
                    if (isObject) {
                      return (
                        <li  {...props} className="subtotal-filter-list">
                          <Checkbox
                            icon={icon}
                            disabled={loading ? true : false}
                            checkedIcon={checkedIcon}
                            checked={filtersData[field].map(it => it[firstKeyOption]).includes(option[firstKeyOption])}
                            size="small"
                            sx={{
                              paddingTop: "3px",
                              paddingBottom: "3px",
                            }}
                          />
                          <div className="subtotal-filter-checkbox">
                            <div className="subtotal-filter-name">{option[firstKeyOption]}</div>
                            <div className="subtotal-filter-dots">{option[secondKeyOption]}</div>
                          </div>
                        </li>
                      )
                    }
                    return (
                      <li  {...props} className="subtotal-filter-list">
                        <Checkbox
                          icon={icon}
                          disabled={loading ? true : false}
                          checkedIcon={checkedIcon}
                          checked={filtersData[field].includes(option)}
                          size="small"
                          sx={{
                            paddingTop: "3px",
                            paddingBottom: "3px",
                          }}
                        />
                        <div className="subtotal-filter-checkbox">
                          <div className="subtotal-filter-name">{option}</div>
                        </div>
                      </li>
                    )
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder={filtersData[field]&&filtersData[field].length ? '' : title}
                        className="subtotal-filter-textfield"
                      />
                    )
                  }}
                />
              </div>
            </div>
          )
        case 'year':
          return (
            <div className="subtotal-filter-section" key={field}>
              <div className="subtotal-filter-label">{title}</div>
              <div className="subtotal-filter-field">
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DatePicker
                    //inputFormat="yyyy-MM-dd"
                    // inputFormat={prefsData && prefsData.date_format}
                    //mask="____-__-__"
                    openTo="year"
                    views={['year']}
                    className='trade-filter'
                    value={filtersData[field] || null}
                    disabled={disabled}
                    onChange={(value) => onChangeYear({YEAR:value})}
                    renderInput={(params) =>
                      <TextField
                        {...params} inputProps={
                          {
                            ...params.inputProps,
                          }
                        }
                      />
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>
          )
        case 'button':
          return (
            <div className="subtotal-filter-section" style={{ 'alignSelf': 'start', paddingTop: '21px' }} key={field}>
              <Button disabled={disabled} style={{ fontSize: '11px', width: '100%' }} size='small' variant="contained" onClick={() => onClick(filtersData)}>{title}</Button>
            </div>
          )
      }
    })
  }

  return (
    <>
      <Accordion className="subtotal-accordion" expanded={expanded === 'panel1'} onChange={(handleChangeExpanded('panel1'))}>
        <AccordionSummary>
          <div>{t("ActualDataInternal.SELECTION_CRITERIA")}</div>
        </AccordionSummary>
        <AccordionDetails>
          <StaticLoaderLinear display={loading} />
          <div className="subtotal-filter-block">
            {displayFilters()}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )

}
